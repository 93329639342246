import * as Icons from "./../../assets/data/icons";

export default function ServiceCard({ icon, header, desc, ...props }) {
    const findIcon = (icon) => {
        const IconSelected = Icons[ icon ];

        return <IconSelected className="mx-auto" />;
    };
    return (
        <>
            <div className="mx-auto mb-10 sm:w-3/5 md:w-3/12 card">
                <div className="text-4xl steelblue-color ">
                    {icon && findIcon(icon)}
                </div>
                <div className="py-2 mx-auto text-center ">
                    <span className="inline-block py-2 text-xl font-semibold leading-relaxed steelblue-color">
                        {" "}
                        {header}
                    </span>
                    <span className="inline-block text-sm font-Roboto ">{desc}</span>
                </div>
            </div>
        </>
    );
}
