export const services = [
    {
        icon: "anl",
        header: "Data Analytics",
        desc: "We have helped various business units across the world extracting useful insights in adding value to their business",
    },
    {
        icon: "ml",
        header: "Machine Learning",
        desc: "Our team of experts have delivered solutions that combines leading marketing automation, data science, AI and data visualization",
    },
    {
        icon: "aiml",
        header: "AI Consulting",
        desc: "With our innovative solutions for artificial intelligence, we help business transform their business ",
    },
    {
        icon: "denew",
        header: "Data Engineering",
        desc: "Combine Your Data for Maximum Impact, our data engineers design, build, and manage big data infrastructures, focusing on the data architecture ",
    },
    {
        icon: "cloud",
        header: "Digital Transformation",
        desc: "Our team of experts utilizing the Digital and Cloud technologies helped companies boost flexibility, cost-effectiveness, and competitiveness ",
    },
    {
        icon: "app",
        header: "Application Development",
        desc: "Web site, Mobile app development has been one of our strengths and our experience in delivering from small to large complex solutions across domains",
    },

    // {
    //     "icon": "",
    //     "header": "Application Development",
    //     "desc": ""
    // }, {
    //     "icon": "",
    //     "header": "Mobile App Development",
    //     "desc": ""
    // }
];
