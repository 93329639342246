import React from 'react'

export default function SideBar() {
    return (
        <>


            <div className='sidebar'>
                {/* <header className="font-semibold text-gray-700 underline inline-flexs underline-offset-4">
                    {" "}
                    Office Location:
                </header>
                <div className="float-left mt-2 mb-2 text-justify contact-box">
                    <h2 className="h5-sm steelblue-color">
                        Colborne Family Dental
                    </h2>
                    <h3 className="text-muted">10 Victoria Square</h3>
                    <h3 className="text-muted">Colborne,ON K0K 1S0</h3>
                    <h3 className="text-muted">Canada</h3>
                    <div className='mt-2'>
                        <p>

                            <svg xmlns="http://www.w3.org/2000/svg" className="inline-flex w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>1-(905)-355-1419</p>
                        <p className='inline-flex'>

                            <svg xmlns="http://www.w3.org/2000/svg" className="inline-flex w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <a
                                href="mailto:help@colbornefamilydental.ca"
                                className="text-primary"
                            >
                                info@colbornefamilydental.ca
                            </a>
                        </p>
                    </div>
                    <div>

                    </div>
                </div> */}
                <div className='p-2'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4267.858489091372!2d80.21977875444466!3d13.060503640850948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52668d995044f1%3A0x57412eb2d4e8c931!2s17-8%2C%2017-8%2C%20Raja%20Veedhi%2C%20Anna%20Nedum%20Pathai%2C%20Uliyanallore%2C%20Veerapandi%20Nagar%2C%20Choolaimedu%2C%20Chennai%2C%20Tamil%20Nadu%20600094%2C%20India!5e0!3m2!1sen!2ssg!4v1656439295112!5m2!1sen!2ssg"
                        width="100%"
                        height="450"
                        border="0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="Sanx Data Analytics Consulting">

                    </iframe>

                </div>
            </div>

        </>
    )
}
