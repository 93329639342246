import { useState } from "react";
import { AiOutlineMail as Mail } from "react-icons/ai";
import { BsTelephone as Phone } from "react-icons/bs";
import logo from "./../../img/logo.png";

export const Navbar = () => {
    const [ active, setActive ] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };

    const links = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "Services",
            link: "#datanalytics",
        },
        {
            name: "Contact Us",
            link: "#contactus",
        },

        "Home",
        "Services",
        "About Us",
        "Contact Us",
    ];
    return (
        <>
            <header className="sticky top-0 z-50 bg-stone-800 ">
                <div
                    className="px-6 mb-0 text-white font-lato"
                    id="contact"
                >
                    <p className="flex float-left gap-2 text-sm font-semibold font-lato ">
                        <Phone />
                        +91 7200065364
                    </p>
                    <p className="flex float-right gap-2 text-sm font-semibold font-lato ">
                        <Mail className="mt-1 font-bold" />
                        info@sanxdata.com
                    </p>
                </div>
                <nav className="inline-flex flex-wrap items-center w-full p-2 mx-auto text-white font-lato ">
                    <a href="/" className="inline-flex items-center p-2 mr-4 ">
                        <span className="gap-4 text-xl font-bold uppercase flexx blue-color">
                            <img src={logo} alt="logo" className="w-12 ml-4 h-20x" />
                            <span className="text-sm ">Sanx Data </span>
                        </span>
                    </a>
                    <button
                        className="inline-flex p-3 ml-auto rounded outline-none hover:bg-green-600 lg:hidden hover:"
                        onClick={handleClick}
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                    {/*Note that in this div we will use a ternary operator to decide whether or not to display the content of the div  */}
                    <div
                        className={`${active ? "" : "hidden"
                            }   w-full lg:inline-flex lg:flex-grow lg:w-auto`}
                    >
                        <div className="flex flex-col items-start w-full mx-auto lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto lg:items-center lg:h-auto">
                            {links.map((x, key) => {
                                return (
                                    <a key={key} href={x.link} className="navlink">
                                        {x.name}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    {/* <div className="inline-flex items-center p-2 mr-4 sm:hidden">
                        <span className="gap-4 text-xl font-semibold font-lato ">
                            <div className=" row-span-1x">
                                <p className="flex gap-2 text-sm font-lato">
                                    <Phone />
                                    <a href="tel:+917200065364"> +91 7200065364</a>
                                </p>
                            </div>
                            <div className="relative ">
                                <p className="flex gap-2 text-sm font-semibold font-lato">
                                    <Mail className="mt-1 font-bold" />
                                    <a href="mailto:info@sanxdata.com">  info@sanxdata.com</a>

                                </p>
                            </div>
                        </span>
                    </div> */}
                    {/* <div
                        className="grid float-right grid-flow-row text-white auto-rows-max sm:hidden lg:visible md:visible xl:visible font-lato"
                        id="contact2x"
                    >
                        <div className=" row-span-1x">
                            <p className="flex gap-2 text-sm font-semibold font-lato">
                                <Phone />
                                +91 7200065364
                            </p>
                        </div>
                        <div className="relative ">
                            <p className="flex gap-2 text-sm font-semibold font-lato">
                                <Mail className="mt-1 font-bold" />
                                info@sanxdata.com
                            </p>
                        </div>
                    </div> */}
                </nav>
            </header>
        </>
    );
};
