import React from "react";

import SideBar from "./Sidebar";
function ContactUsTemp() {
    return (
        <>
            <section id="contactus">
                <div className="container pt-16 mx-auto mb-10">

                    <div className="lg:flex">
                        <div className="py-16 bg-blue-600 rounded-tl rounded-tr xl:w-2/5 lg:w-2/5 xl:rounded-bl xl:rounded-tr-none">
                            <div className="px-8 mx-auto xl:w-5/6 xl:px-0">
                                <h1 className="pb-4 text-3xl font-bold text-white xl:text-4xl">
                                    Get in touch
                                </h1>
                                <p className="pb-8 text-xl font-normal leading-relaxed text-white lg:pr-4">
                                    Got a question about us? Are you interested in partnering with
                                    us? Have some suggestions or just want to say Hi? Just contact
                                    us. We are here to asset you.
                                </p>
                                <div className="flex items-center pb-4">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-phone-call"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="#ffffff"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <path d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1" />
                                            <path d="M15 7a2 2 0 0 1 2 2" />
                                            <path d="M15 3a6 6 0 0 1 6 6" />
                                        </svg>
                                    </div>
                                    <p className="pl-4 text-base text-white"> +91 7200065364</p>
                                </div>
                                <div className="flex items-center">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-mail"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="#FFFFFF"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <rect x={3} y={5} width={18} height={14} rx={2} />
                                            <polyline points="3 7 12 13 21 7" />
                                        </svg>
                                    </div>
                                    <p className="pl-4 text-base text-white">info@sanxdata.com</p>
                                </div>
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-5 h-5 text-white"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <p className="pt-10 ml-4 text-lg tracking-wide text-white">
                                        Choolaimedu ,Chennai <br />
                                        Tamilnadu, India
                                    </p>
                                </div>

                                {/* <a href="/">
                            <p className="pt-16 font-bold tracking-wide text-white underline">View Job Openings</p>
                        </a> */}
                            </div>
                        </div>
                        <div className="w-full h-full pt-5 pb-5 bg-gray-100 rounded-tr rounded-br">
                            <h1 className="mb-6 text-4xl font-extrabold text-gray-800">
                                Contact Us
                            </h1>
                            <SideBar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUsTemp;
