import React from "react";
import { SiDatabricks } from "react-icons/si";
import { BsTelephone as Phone } from "react-icons/bs";
import { AiOutlineMail as Mail } from "react-icons/ai";

export const DataAnalyticsCard = () => {
    return (
        <>
            <figure className="p-8 mx-auto text-white shadow-2xl shadow-slate-400 lg:w-80 rounded-xl bg-slate-800">
                <SiDatabricks className="w-24 h-24 mx-auto rounded-full" />
                <div className="pt-6 space-y-4">
                    <blockquote>
                        <p className="text-lg font-medium">
                            As a data analytics consulting company our experts can help your
                            organization execute business solutions using vital digital data
                        </p>
                    </blockquote>
                    <figcaption>
                        <div>
                            <p className="text-xl font-semibold">Contact Us:</p>
                        </div>
                        <div>
                            <p className="flex gap-1 text-sm font-semibold ">
                                <Phone />
                                +91 7200065364
                            </p>
                        </div>
                        <div>
                            <p className="flex gap-1 text-sm font-semibold ">
                                <Mail className="mt-1" />
                                info@sanxdata.com
                            </p>
                        </div>
                    </figcaption>
                </div>
            </figure>
        </>
    );
};
