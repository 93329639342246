import * as techimages from "../assets/data/tech";
import { TechCard } from "../components/Cards/TechCard";

export const Technologies = () => {

    return (
        <>
            <div className="container bg-white" id="tech">
                <h3 className="steelblue-color">Technologies</h3>
                <p className="mx-auto text-center text-gray-500 pros">
                    New technologies have helped us adapt to constant change in customers’ requirements, with the exponential growth in data volumes we were able to provide and maintain quality assurance in delivering data analysis services.
                </p>
                <div className="grid grid-cols-4 py-5 mx-auto">
                    {/* {
            techs.map((x, item) => { */}
                    <TechCard src={techimages.python} />
                    <TechCard src={techimages.aws} />
                    <TechCard src={techimages.gcp} />
                    <TechCard src={techimages.tbl} />
                    <TechCard src={techimages.azure} />

                    <TechCard src={techimages.r} />
                    <TechCard src={techimages.scala} />
                    <TechCard src={techimages.keras} />
                    <TechCard src={techimages.pytorch} />
                    <TechCard src={techimages.tensorflo} />
                    {/* <TechCard src={techimages.hadoop} /> */}

                    {/* })
          } */}
                </div>
            </div>
        </>
    );
};
