
export default function Hero() {
    return (
        <>
            <header className="my-4x">
                <div className="w-full bg-center bg-cover herobg"
                >
                    <div className="flex items-center justify-center w-full h-full py-12 bg-gray-900 bg-opacity-70">
                        <div className="text-center">
                            <div className="container px-4 mx-auto">
                                <div className="max-w-4xl mx-auto text-center">
                                    <h2 className="mt-8 mb-6 text-4xl font-bold text-white lg:text-4xl font-lato">Data Analytics & Digital Transformation Experts</h2>
                                    <p className="max-w-3xl mx-auto mb-10 text-2xl text-white font-lato">
                                        We build reliable products using Machine Learning and Data Engineering solutions & technologies                                    </p>
                                    {/* <button className="inline-block w-full px-8 py-5 mb-4 text-sm font-bold text-gray-800 uppercase transition duration-200 bg-gray-200 border-2 border-transparent rounded btn-primary md:w-auto md:mr-6 hover:bg-gray-100"
                                    >Contact Us</button> */}
                                    <a href="#contactus" className="text-xl btn-primary">
                                        Lets Talk</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
}
