import { useEffect } from 'react';
import ReactGA from "react-ga";
import './App.css';
import ContactUsTemp from './components/ContactUs/ContactUsTemp';
import { DataAnalyticsConsulting } from './components/DataAnalyticsConsulting';
import Hero from './components/Hero';
import Footer from './components/infra/Footer';
import Layout from './components/infra/Layout';
import { Navbar } from './components/infra/Navrbar';
import { ServicesPage } from './pages/Services';
import { Technologies } from './pages/Technologies';

function App() {

  const TRACKING_ID = "G-RS1QWTTXRT"; // YOUR_OWN_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);

  // GOOGLE ANALYTICS
  // ReactGA.initialize(
  //   [
  //     {
  //       trackingId: process.env.REACT_APP_GA_ID,
  //     }
  //   ]
  // );

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {

      ReactGA.pageview(window.location.pathname + window.location.search);

    }

  }, []);

  return (
    <div className='app'>
      <Navbar />
      <section id="hero">
        <Hero />
      </section>
      <Layout>
        <ServicesPage />
        <DataAnalyticsConsulting />
        <Technologies />
      </Layout>
      {/* <ContactUs /> */}
      <ContactUsTemp />
      <Footer />
    </div>
  );
}

export default App;
