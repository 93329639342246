import React from "react";
import { BiAbacus } from "react-icons/bi";
import { SiDatabricks } from "react-icons/si";
import { MdAutoGraph, MdOutlineMiscellaneousServices } from "react-icons/md";
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4 } from "react-icons/ri";
import { DataAnalyticsCard } from "./Cards/DataAnalyticsCard";
export const DataAnalyticsConsulting = () => {
    return (
        <>
            <section className="container mb-5 bg-gray-100x" id="datanalytics">
                <div className="py-5 text-gray-700 dabriefnote ">
                    <h3 className=" steelblue-color">
                        Data Analytics Consulting Services
                    </h3>
                    <article>
                        <p>
                            With the influx of data availability and tool chains have made it
                            easier and cheaper for collecting and storing data. Churning data
                            into value and money – is what companies struggle with. SanX data
                            data analytics experts have a lot of experience working with
                            various companies to help them execute business information
                            management solutions using gathered data.

                        </p>
                        <p>
                            we build solutions and design reporting and data visualization
                            systems based on the large amount of collected data, that brings
                            huge value for the decision makers. With digitalization and niche
                            technologies like IoT, revolutionized customer interactions, every
                            day contributes to the increasing number of structured and
                            unstructured data. Companies face the challenges of how to use the
                            collected data. SanX data team believes that the ability to use
                            collected data is the basis for the effective functioning of a
                            company on the marke

                        </p>
                        <p>
                            SanX data can help your business to achieve better results and help
                            you plan. Let’s begin a transformation with statistical and
                            analytical services.

                        </p>
                    </article>
                </div>
                <div className="flex flex-wrap">
                    <div >
                        {" "}
                        <DataAnalyticsCard />{" "}
                    </div>

                    <div id="cont" className="py-3 mx-auto">
                        <div
                            id="detail"
                            className="mx-auto text-center md:flex"
                        >
                            <div id="stats" className="mx-autox">
                                <BiAbacus className="mx-auto text-6xl iconColor" />
                                <p className="font-semibold iconColor">
                                    Statistical Data Analysis
                                </p>
                            </div>
                            <div id="di" className="mx-autxo">
                                <SiDatabricks className="mx-auto text-6xl iconColor " />
                                <p className="font-semibold iconColor">Data Integration</p>
                            </div>
                            <div id="dv" className="mx-autox">
                                <MdAutoGraph className="mx-auto text-6xl iconColor" />
                                <p className="font-semibold iconColor">Data visualization</p>
                            </div>
                            <div id="au" className="mx-autox ">
                                <MdOutlineMiscellaneousServices className="mx-auto text-6xl iconColor md:ml-8 " />
                                <p className="font-semibold iconColor">Reports Automation</p>
                            </div>
                        </div>
                        <div id="working" className="justify-center py-3 mx-auto ">
                            <h3 className="steelblue-color">Our Process</h3>

                            <div className="justify-center gap-5 py-3 mx-auto text-center md:flex md:flex-wrap sm:text-center">
                                <div className="w-1/5 mx-auto">
                                    <RiNumber1 className="px-3 mx-auto text-6xl font-bold text-white rounded-full numberIcon" />
                                    <p className="py-3 text-sm"> Discovery Phase</p>
                                </div>
                                <div className="w-1/5 mx-auto">
                                    <RiNumber2 className="px-3 mx-auto text-6xl font-bold text-white rounded-full numberIcon" />
                                    <p className="py-3 text-sm text-center">Data analysis</p>
                                </div>
                                <div className="w-1/5 mx-auto">
                                    <RiNumber3 className="px-3 mx-auto text-6xl font-bold text-white rounded-full numberIcon" />
                                    <p className="py-3 text-sm">Statistical data analysis</p>
                                </div>
                                <div className="w-1/5 mx-auto">
                                    <RiNumber4 className="px-3 mx-auto text-6xl font-bold text-white rounded-full numberIcon" />
                                    <p className="flex-wrap py-3 text-sm font-light text-center iniline-block leading-relaxedx">
                                        Reports on extracted insights and knowledge sharing
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
