import { services } from "../assets/data/services";
import ServiceCard from "../components/Cards/ServiceCard";
export const ServicesPage = () => {
    return (
        <>
            <section id="services">
                <div className="container mx-auto mb-10 bg-white w-screenx" id="servicesx">
                    <h3 className="steelblue-color ">Our Expertise</h3>
                    <div className="mx-auto lg:ml-10 lg:flex lg:flex-wrap" >
                        {services.map((item, key) => {
                            return (
                                <ServiceCard
                                    className="inline-block"
                                    key={key}
                                    icon={item.icon}
                                    header={item.header}
                                    desc={item.desc}
                                />
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};
